<template>
  <div class="login">
    <h2 class="title">汇通易货——后台管理系统</h2>
    <div class="containL">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item prop="No">
          <el-input placeholder="账号" v-model="ruleForm.No"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="密码"
            v-model="ruleForm.password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="verify-val" prop="verifyCode">
          <el-input
            style="width: 50%"
            placeholder="请输入验证码"
            v-model="ruleForm.verifyCode"
            @blur="onInputBlur"
          ></el-input>
          <div class="vrifyVal">
            <span v-show="veryifyValErr">验证码错误</span
            ><span style="color: green" v-show="veryifyValSecc">验证通过</span>
          </div>
          <div
            class="verify-image"
            @click="toggleVerify"
            v-html="verifyCodeImg"
          ></div>
          <span class="verifyText" @click="verifyUpdate"
            >看不清楚，点击刷新</span
          >
        </el-form-item>
        <el-form-item prop="remember">
          <el-checkbox style="margin-left: 10%" v-model="ruleForm.remember"
            >记住密码</el-checkbox
          ></el-form-item
        >
        <el-form-item>
          <el-button
            style="width: 50%; margin-left: 25%"
            type="primary"
            @click="submitForm('ruleForm')"
            :disabled="logIn"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
      <div class="sign">
        <span>深圳聚合科技有限公司@202205 | 汇通易货 |</span>
        <a class="put-on" href="https://beian.miit.gov.cn"
          >&nbsp;豫ICP备2022014588号-1</a
        >
        <div>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41090202000505"
            style="
              display: inline-block;
              text-decoration: none;
              margin-left: 15px;
            "
            ><img src="../assets/备案图标.png" style="float: left" />
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #ffffff;
              "
            >
              豫公网安备 41090202000505号
            </p></a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
import moment from "moment";
import _ from "@/utils/md5.js";
import _ip from '@/utils/ip.js'
("");
// import { _debounce } from "@/utils/public"
export default {
  inject: ["reload"],
  data() {
    return {
      msg: "濮阳",
      loadIp: "",
      ruleForm: {
        No: "",
        username: "",
        password: "",
        remember: false,
        verifyCode: "",
      },
      verifyCodeImg: "",
      veryifyValSecc: false,
      veryifyValErr: false,
      logIn: true,
      rules: {
        No: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 6,
            max: 11,
            message: "长度在 6 到 11 个字符",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "密码", trigger: "change" }],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    console.log(_.md5("992chuan"));
    console.log('ipFun:',_ip.ipFun());
    // const ip = _ip.ipFun();
  },
  async created() {
    await axios.post(this.GLOBAL.api + "/verifyCode").then((data) => {
      this.verifyCodeImg = data.data.captchaData;
      this.$session.set("verifyCode", data.data.captchaText);
    });
    if (this.$cookies.get("account")) {
      const cookiesInfo = this.$cookies.get("account");
      this.ruleForm.No = cookiesInfo.No;
      this.ruleForm.password = secret.Decrypt(cookiesInfo.password); // 解密
      this.ruleForm.remember = cookiesInfo.remember;
    }
  },
  methods: {
    toggleVerify() {
      axios.post(this.GLOBAL.api + "/verifyCode").then((data) => {
        this.verifyCodeImg = data.data.captchaData;
        this.$session.set("verifyCode", data.data.captchaText);
      });
    },
    onInputBlur() {
      const veryifyCode = secret.Decrypt(this.$session.get("verifyCode"));
      const inVerify = this.ruleForm.verifyCode.toUpperCase();
      const comparison = inVerify === veryifyCode ? true : false;
      if (comparison) {
        this.veryifyValSecc = true;
        this.veryifyValErr = false;
        this.logIn = false;
      } else {
        this.veryifyValErr = true;
        this.veryifyValSecc = false;
        this.logIn = true;
      }
    },
    verifyUpdate() {
      axios.post(this.GLOBAL.api + "/verifyCode").then((data) => {
        this.verifyCodeImg = data.data.captchaData;
        this.$session.set("verifyCode", data.data.captchaText);
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    submitForm(formName) {
      sessionStorage.setItem(
        "loginTime",
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const cardNo = this.ruleForm.No;
          const password = secret.Encrypt(this.ruleForm.password);
          if (
            this.ruleForm.No &&
            this.ruleForm.password &&
            this.ruleForm.verifyCode
          ) {
            axios
              .post(this.GLOBAL.api + "/login", { cardNo, password })
              .then((data) => {
                if (data.data.statusCode === 400) {
                  this.$message({
                    message: "账号或密码错误",
                    type: "warning",
                  });
                } else if (data.data.statusCode === 500) {
                  this.$message({
                    message: "该账号已停用，请联系管理员！",
                    type: "worning",
                  });
                  this.reload();
                } else {
                  this.$message({
                    message: "登陆成功",
                    type: "success",
                  });
                  const localIp = this.GLOBAL.api
                  localStorage.setItem("access_token", data.data.token);
                  localStorage.setItem("user_id", data.data.username);
                  localStorage.setItem(
                    "user_key",
                    secret.Encrypt(data.data.user_key)
                  );
                  localStorage.setItem(
                    "sideMenu",
                    secret.Encrypt(data.data.verify)
                  );
                  // localStorage.setItem('username', this.ruleForm.username)
                  // this.$cookies.set("sideMenu", secret.Encrypt(data.data.verify));
                  this.$router.push("/homepage");
                  this.ruleForm.username = data.data.username;
                  this.ruleForm.password = secret.Encrypt(
                    this.ruleForm.password
                  );
                  if (this.ruleForm.remember) {
                    this.$cookies.set("account", this.ruleForm, { expires: 7 });
                  } else {
                    this.$cookies.remove("account");
                  }
                  // ip
                    const loginInfoLogs = {
                      userNo: this.ruleForm.No,
                      user_key: data.data.user_key,
                      username: this.ruleForm.username,
                      password: this.ruleForm.password,
                      ip: this.loadIp,
                    };
                    loginInfoLogs.loginTime = moment().format("YYYY-MM-DD HH:mm:ss");
                    const created = true;

                    // let _script = document.createElement("script");
                    // _script.setAttribute("charset", "utf-8");
                    // _script.setAttribute("type", "text/javascript");
                    // _script.setAttribute(
                    //   "src",
                    //   "https://pv.sohu.com/cityjson?ie=utf-8"
                    // );
                    // document
                    //   .getElementsByTagName("head")[0]
                    //   .appendChild(_script);
                    // _script.onload = function () {
                      console.log(_ip.ipFun().cip);
                      this.loadIp = _ip.ipFun().cip;
                      loginInfoLogs.ip = _ip.ipFun().cip;
                      loginInfoLogs.province = _ip.ipFun().cname
                      // console.log("获取IP:", _ip.ipFun().cip);
                      // console.log("接口:", localIp);
                      axios.post(localIp + "/logs", { loginInfoLogs, created })
                        .then((res) => {
                          this.$message({
                            type: "success",
                            message: `当前登陆地址为：${_ip.ipFun().cip} 登陆时间: ${loginInfoLogs.loginTime}`,
                          });
                        })
                        .catch((err) => {
                          this.$message({
                            type: "error",
                            message: `当前登陆违规，已记录登陆信息！ERROR:${err}`,
                          });
                        });
                    // };
                }
              });
          } else {
            this.$message({
              message: "登陆信息输入有误",
              type: "worning",
            });
          }
        } else {
          this.$message({
            message: "登陆信息输入有误",
            type: "worning",
          });
          return false;
        }
      });
    },
  },
};
</script>
<style>
* {
  margin: 0 0;
  padding: 0 0;
}
body {
  background-color: #409eff;
}
.login {
  width: 100%;
}
.title {
  color: aliceblue;
  margin: 10% 0 2% 0;
  text-align: center;
}
.containL {
  width: 20%;
  height: 80%;
  background-color: #ebd795;
  padding: 5%;
  margin: 0 auto;
  border-radius: 2%;
}
.demo-ruleForm {
  width: 100%;
}
.sign {
  position: fixed;
  display: flex;
  bottom: 1%;
  right: 20%;
  color: #ffffff;
  font-size: 8px;
}
.put-on {
  color: #ffffff;
  text-decoration: none;
}
.verify-val {
  position: relative;
}
.verify-image {
  position: absolute;
  left: 52%;
  top: 2%;
  height: auto;
}
.vrifyVal span {
  position: absolute;
  color: red;
  top: 70%;
}
.verifyText {
  position: absolute;
  left: 53%;
  top: 60%;
  font-size: 8px;
  cursor: pointer;
}
</style>