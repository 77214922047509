function ipFun() {
    let _script = document.createElement("script");
    _script.setAttribute("charset", "utf-8");
    _script.setAttribute("type", "text/javascript");
    _script.setAttribute(
        "src",
        "https://pv.sohu.com/cityjson?ie=utf-8"
    );
    document
        .getElementsByTagName("head")[0]
        .appendChild(_script);
    return returnCitySN
}
export default {
    ipFun
}